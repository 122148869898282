import React, { useEffect, useState } from 'react';
import edit from "./edit.module.css";
import { get } from 'lodash';
import { useAction, useSelect } from '../../../hooks';
import Select from 'react-select'
import * as c from "./constants";
import { Formik, Form, Field } from "formik"
import Input from "../../Input/Input";
import { api } from '../../../api';
import Cookie from 'js-cookie';

const EditContact = ({profileSelected, setShowUpdate, setActiveTab}) => {
  const updateProfile = useAction('profile.updateProfile');
  const regionDropdown = useSelect('dropdown.regionDropdown');
  const provinceDropdown = useSelect('dropdown.provinceDropdown');
  const munincipalityDropdown = useSelect('dropdown.munincipalityDropdown');
  const barangayDropdown = useSelect('dropdown.barangayDropdown');

  return (
    <>
    <Formik
      initialValues={{
        email: get(profileSelected, 'email', "--"),
        mobile_number: get(profileSelected, 'profile.mobile_number', "--"),
        telephone_number: get(profileSelected, 'profile.telephone_number', "--"),
        region_code: get(profileSelected, 'profile.region_code.code', "--"),
        province_code: get(profileSelected, 'profile.province_code.code', "--"),
        municipality_code: get(profileSelected, 'profile.municipality_code.code', "--"),
        barangay_code: get(profileSelected, 'profile.barangay_code.code', "--"),
        street: get(profileSelected, 'profile.street', "--")

      }}
      onSubmit={async (values) => {
        updateProfile(values);
        console.log("onSubmit", values)
      }}
    >
     {(formikProps) => (
      <Form style={{height: '100%'}}>
           <div className={edit["container-filled"]}>
      <div className={edit["field-group"]}>
        <div className={edit["contact-information"]}>
          Contact Information{" "}
        </div>
        <div className={edit["row"]}>
          <div className={edit["input-text"]}>
            <div className={edit["label"]}>
              <div className={edit["label-and-icon"]}>
                <div className={edit["label2"]}>Email Address </div>
                <svg
                  className={edit["check"]}
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.5 6.5L4.5 8.5L9.5 3.5"
                    stroke="#54D468"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <Input 
              type="text"
              name="email"
              className={edit["input-field"]}
              required
            />
          </div>
          <div className={edit["input-mobile"]}>
            <div className={edit["label"]}>
              <div className={edit["label-and-icon"]}>
                <div className={edit["label2"]}>Mobile No. </div>
                <svg
                  className={edit["check2"]}
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.5 6.5L4.5 8.5L9.5 3.5"
                    stroke="#54D468"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <Input 
              type="text"
              name="mobile_number"
              className={edit["input-field"]}
              required
            />
          </div>
        </div>
        <div className={edit["row"]}>
          <div className={edit["input-inline-text"]}>
            <div className={edit["label"]}>
              <div className={edit["label-and-icon"]}>
                <div className={edit["label2"]}>Telephone No. </div>
                <svg
                  className={edit["form-required"]}
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.78841 3.76853L10.3884 4.84853L8.82841 5.41253L10.3884 5.97653L9.76441 7.09253L8.46841 6.02453L8.74441 7.68053H7.49641L7.76041 6.02453L6.47641 7.11653L5.81641 5.96453L7.37641 5.40053L5.82841 4.86053L6.44041 3.75653L7.77241 4.81253L7.49641 3.14453H8.75641L8.46841 4.81253L9.78841 3.76853Z"
                    fill="#DB4343"
                  />
                </svg>
              </div>
            </div>
            <Input 
              type="text"
              name="telephone_number"
              className={edit["input-field"]}
              required
            />
          </div>
        </div>
      </div>
      <div className={edit["field-group"]}>
        <div className={edit["address"]}>Address </div>
        <div className={edit["row"]}>
          <div className={edit["selection-dropdown"]}>
            <div className={edit["label"]}>
              <div className={edit["label-and-icon"]}>
                <div className={edit["label2"]}>Region </div>
                <svg
                  className={edit["check3"]}
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.5 6.5L4.5 8.5L9.5 3.5"
                    stroke="#54D468"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <Select
                    options={regionDropdown || []}
                    value={(regionDropdown || []).find(option => option.value === formikProps.values.region_code)}
                    onChange={(option) => formikProps.setFieldValue('region_code', option.value)}
                    className={edit["input-select"]}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? '#E4E4E4' : '#E4E4E4',
                            height: 40,
                            borderRadius: 4
                        }),
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary25: '#beac6f',
                            primary: '#beac6f',
                        },
                    })}
                />      
          </div>
          <div className={edit["selection-dropdown"]}>
            <div className={edit["label"]}>
              <div className={edit["label-and-icon"]}>
                <div className={edit["label2"]}>Province </div>
                <svg
                  className={edit["check4"]}
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.5 6.5L4.5 8.5L9.5 3.5"
                    stroke="#54D468"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <Select
                    options={provinceDropdown || []}
                    value={(provinceDropdown || []).find(option => option.value === formikProps.values.province_code)}
                    onChange={(option) => formikProps.setFieldValue('province_code', option.value)}
                    className={edit["input-select"]}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? '#E4E4E4' : '#E4E4E4',
                            height: 40,
                            borderRadius: 4
                        }),
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary25: '#beac6f',
                            primary: '#beac6f',
                        },
                    })}
                />    
          </div>
        </div>
        <div className={edit["row"]}>
          <div className={edit["selection-dropdown"]}>
            <div className={edit["label"]}>
              <div className={edit["label-and-icon"]}>
                <div className={edit["label2"]}>City / Municipality </div>
                <svg
                  className={edit["check5"]}
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.5 6.5L4.5 8.5L9.5 3.5"
                    stroke="#54D468"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <Select
                    options={munincipalityDropdown || []}
                    value={(munincipalityDropdown || []).find(option => option.value === formikProps.values.municipality_code)}
                    onChange={(option) => formikProps.setFieldValue('munincipality_code', option.value)}
                    className={edit["input-select"]}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? '#E4E4E4' : '#E4E4E4',
                            height: 40,
                            borderRadius: 4
                        }),
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary25: '#beac6f',
                            primary: '#beac6f',
                        },
                    })}
                />    
          </div>
          <div className={edit["selection-dropdown"]}>
            <div className={edit["label"]}>
              <div className={edit["label-and-icon"]}>
                <div className={edit["label2"]}>Barangay </div>
                <svg
                  className={edit["check6"]}
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.5 6.5L4.5 8.5L9.5 3.5"
                    stroke="#54D468"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <Select
                    options={barangayDropdown || []}
                    value={(barangayDropdown || []).find(option => option.value === formikProps.values.barangay_code)}
                    onChange={(option) => formikProps.setFieldValue('barangay_code', option.value)}
                    className={edit["input-select"]}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? '#E4E4E4' : '#E4E4E4',
                            height: 40,
                            borderRadius: 4
                        }),
                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary25: '#beac6f',
                            primary: '#beac6f',
                        },
                    })}
                />    
          </div>
        </div>
        <div className={edit["row"]}>
          <div className={edit["input-text"]}>
            <div className={edit["label"]}>
              <div className={edit["label-and-icon"]}>
                <div className={edit["label2"]}>Address Line </div>
                <svg
                  className={edit["check7"]}
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.5 6.5L4.5 8.5L9.5 3.5"
                    stroke="#54D468"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <Input 
              type="text"
              name="street"
              className={edit["input-field"]}
              required
            />
          </div>
        </div>
      </div>
      <div className={edit["buttons"]}>
        <div className={edit["buttons2"]}>
          <div className={edit["solid-button"]} style={{cursor: "pointer"}}>
            <div className={edit["button"]} onClick={() => setShowUpdate(false)}>Cancel </div>
          </div>
          <div className={edit["solid-button2"]} style={{cursor: "pointer"}}>
            <div className={edit["content"]}>
              <div className={edit["button2"]} onClick={() => formikProps.submitForm()}>Update Changes </div>
            </div>
          </div>
        </div>
        <div className={edit["arrow-buttons"]}>
          <div className={edit["icon-button"]} style={{cursor: "pointer"}} onClick={() => setActiveTab(1)}>
            <svg
              className={edit["arrow-narrow-left"]}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.16529 11.7657C5.85287 12.0781 5.34634 12.0781 5.03392 11.7657L1.83392 8.56569C1.5215 8.25327 1.5215 7.74673 1.83392 7.43432L5.03392 4.23431C5.34634 3.9219 5.85287 3.9219 6.16529 4.23431C6.47771 4.54673 6.47771 5.05327 6.16529 5.36569L4.33098 7.2L13.5996 7.2C14.0414 7.2 14.3996 7.55817 14.3996 8C14.3996 8.44183 14.0414 8.8 13.5996 8.8L4.33098 8.8L6.16529 10.6343C6.47771 10.9467 6.47771 11.4533 6.16529 11.7657Z"
                fill="#606774"
              />
            </svg>
          </div>
          <div className={edit["icon-button"]} style={{cursor: "pointer"}} onClick={() => setActiveTab(3)}>
            <svg
              className={edit["arrow-narrow-right"]}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.83391 4.23431C10.1463 3.9219 10.6529 3.9219 10.9653 4.23431L14.1653 7.43431C14.4777 7.74673 14.4777 8.25327 14.1653 8.56569L10.9653 11.7657C10.6529 12.0781 10.1463 12.0781 9.83391 11.7657C9.52149 11.4533 9.52149 10.9467 9.83391 10.6343L11.6682 8.8H2.39961C1.95778 8.8 1.59961 8.44183 1.59961 8C1.59961 7.55817 1.95778 7.2 2.39961 7.2H11.6682L9.83391 5.36569C9.52149 5.05327 9.52149 4.54673 9.83391 4.23431Z"
                fill="#606774"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    </Form>
      )}
    </Formik>
    </>
  );
};

export default EditContact;
