// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_icon-button__zYQ4U,
.style_icon-button__zYQ4U * {
  box-sizing: border-box;
}
.style_icon-button__zYQ4U {
  background: var(--utility-danger-base, #db4343);
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}
.style_trash__HBDKJ {
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}
`, "",{"version":3,"sources":["webpack://./src/components/Profile/WorkHistory/style.module.css"],"names":[],"mappings":"AAAA;;EAEE,sBAAsB;AACxB;AACA;EACE,+CAA+C;EAC/C,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;EACd,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".icon-button,\n.icon-button * {\n  box-sizing: border-box;\n}\n.icon-button {\n  background: var(--utility-danger-base, #db4343);\n  border-radius: 4px;\n  padding: 8px;\n  display: flex;\n  flex-direction: row;\n  gap: 8px;\n  align-items: center;\n  justify-content: center;\n  flex-shrink: 0;\n  position: relative;\n  overflow: hidden;\n}\n.trash {\n  flex-shrink: 0;\n  position: relative;\n  overflow: visible;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon-button": `style_icon-button__zYQ4U`,
	"trash": `style_trash__HBDKJ`
};
export default ___CSS_LOADER_EXPORT___;
